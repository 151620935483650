<template>
    <v-row justify="center" class="mt-2">
        <v-expansion-panels inset focusable>
            <v-expansion-panel
                v-for="setting in companySettingsList"
                :key=setting.id
            >
                <v-expansion-panel-header icon-rotate>
                    <v-row no-gutters>
                        <v-col cols="4">
                            {{ setting.name }}
                        </v-col>
                        <v-col
                            cols="8"
                            class="text--secondary"
                        >
                            <v-fade-transition leave-absolute>
                                <v-row
                                    no-gutters
                                    style="width: 100%"
                                >
                                    <v-col cols="12">
                                        {{ setting.description }}
                                    </v-col>
                                </v-row>
                            </v-fade-transition>
                        </v-col>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-list
                        flat
                        subheader
                        three-line
                    >
                        <v-list-item-group
                            multiple
                            active-class=""
                        >
                            <v-list-item>
                                <v-list-item-content>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            class="text-right"
                                        >
                                            <v-btn
                                                color="primary"
                                                small
                                                @click="setModalConfigObj(setting);is_show.addSetting=true;"
                                            >
                                                CREA NUOVO
                                            </v-btn>
                                        </v-col>
                                    </v-row>

                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                v-for="(item, index) in setting.company_setting_values"
                                :key=index
                            >
                                <template>
                                    <v-list-item-content>
                                        <v-row>
                                            <v-col
                                                cols="1"
                                            >
                                                <v-icon
                                                    v-if="item.status === 'enabled'"
                                                    color="success"
                                                    class="mr-2"
                                                >
                                                    mdi-checkbox-marked
                                                </v-icon>
                                                <v-icon
                                                    v-else
                                                    color="lightgray"
                                                    class="mr-2"
                                                >
                                                    mdi-minus-box
                                                </v-icon>
                                            </v-col>
                                            <v-col
                                                cols="3"
                                            >
                                                <v-chip
                                                    label
                                                    :color="item.color"
                                                    :text-color="item.color_text"
                                                >
                                                    {{ item.name }}
                                                </v-chip>
                                            </v-col>
                                            <v-col
                                                cols="6"
                                            >
                                                <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
                                            </v-col>
                                            <v-col
                                                cols="2"
                                                class="d-flex justify-end"
                                            >
                                                <v-menu
                                                    bottom
                                                    left
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                            icon
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            <v-icon>mdi-dots-vertical</v-icon>
                                                        </v-btn>
                                                    </template>

                                                    <v-list>
                                                        <v-list-item
                                                            @click="setModalConfigItemObj(item);is_show.editSettingItem=true;"
                                                        >
                                                            <v-list-item-title>
                                                                <v-icon
                                                                    left
                                                                    small
                                                                >
                                                                    mdi-pencil
                                                                </v-icon>
                                                                Modifica
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item
                                                            v-if="item.company_id > 0 && item.status !== 'disabled'"
                                                            @click="setModalConfigItemObj(item);is_show.deleteSettingItem=true;"
                                                        >
                                                            <v-list-item-title>
                                                                <v-icon
                                                                    left
                                                                    small
                                                                >
                                                                    mdi-delete
                                                                </v-icon>
                                                                Disabilita
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item
                                                            v-else-if="item.company_id > 0 && item.status === 'disabled'"
                                                            @click="setModalConfigItemObj(item);is_show.reactivateSettingItem=true;"
                                                        >
                                                            <v-list-item-title>
                                                                <v-icon
                                                                    left
                                                                    small
                                                                >
                                                                    mdi-autorenew
                                                                </v-icon>
                                                                Riattiva
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                    </v-list-item-content>
                                </template>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <create-setting :is_show="is_show.addSetting" :modalConfigObj = modalConfigObj @close="is_show.addSetting=false" @reload-data="reloadData"/>
        <edit-setting :is_show="is_show.editSettingItem" :modalConfigItemObj = modalConfigItemObj @close="is_show.editSettingItem=false" @reload-data="reloadData"/>
        <delete-setting :is_show="is_show.deleteSettingItem" :modalConfigItemObj = modalConfigItemObj @close="is_show.deleteSettingItem=false" @reload-data="reloadData"/>
        <reactivate-setting :is_show="is_show.reactivateSettingItem" :modalConfigItemObj = modalConfigItemObj @close="is_show.reactivateSettingItem=false" @reload-data="reloadData"/>

    </v-row>
</template>

<script>
import {mapGetters} from "vuex";
import createSetting from "@/components/modals/create-setting";
import editSetting from "@/components/modals/edit-setting";
import deleteSetting from "@/components/modals/delete-setting";
import reactivateSetting from "@/components/modals/reactivate-setting";

export default {
    name: "configs",
    components: {
        createSetting,
        editSetting,
        deleteSetting,
        reactivateSetting
    },
    computed: {
        ...mapGetters(['companySettingsList'])
    },
    data() {
        return {
            is_show:{
                'addSetting': false,
                'editSettingItem': false,
                'deleteSettingItem': false,
                'reactivateSettingItem': false
            },
            modalConfigObj: {},
            modalConfigItemObj: {},
            breadCrumb: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                },
                {
                    text: 'Configurazioni',
                    to: '/configs',
                },
            ]
        }
    },
    mounted() {
        this.$store.dispatch('setBreadCrumbLevels',this.breadCrumb)
    },
    methods: {
        setModalConfigObj(modalConfigObj) {
            this.modalConfigObj = modalConfigObj
        },
        setModalConfigItemObj(modalConfigItemObj) {
            this.modalConfigItemObj = modalConfigItemObj
        },
        reloadData(){
            this.$store.dispatch('reload_specific_data')
        }
    }
}
</script>