<template>
    <div>
        <v-dialog
            v-model="is_show"
            persistent
            max-width="600px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">Riattiva settaggio</span>
                </v-card-title>
                <v-card-text>
                    <v-row class="ma-0">
                        <v-col
                            cols="12"
                        >
                            <p>Riattivando il settaggio, le pratiche che lo contengono continueranno a visualizzarlo, accertarsi di voler continuare.</p>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        @click="closeModal"
                        class="ma-2"
                        :disabled="loading"
                    >
                        Chiudi
                    </v-btn>
                    <v-spacer />
                    <v-btn
                        @click.prevent="reactivateSetting"
                        color="primary"
                        class="ma-2"
                        :loading="loading"
                    >
                        Riattiva
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>

</template>

<script>

export default {
    name: "reactivate-setting",
    props: {
        is_show: {
            type: Boolean,
            default: false
        },
        modalConfigItemObj: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        async reactivateSetting() {
            try {
                this.loading = true
                await this.$api.reactivateSetting(this.modalConfigItemObj.id)
                await this.$store.dispatch('set_alert', {msg: "Settaggio riabilitato con successo", type: 'success'})
                this.$emit('reload-data')
                this.closeModal()
            } catch (e) {
                this.loading = false
                await this.$store.dispatch('set_alert', {msg: "error", type: 'error'})
            }
        },
        closeModal() {
            this.$emit('close');
            this.loading = false
        }
    }
}
</script>

<style scoped>

</style>