import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.is_show),callback:function ($$v) {_vm.is_show=$$v},expression:"is_show"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v("Disabilita settaggio")])]),_c(VCardText,[_c(VRow,{staticClass:"ma-0"},[_c(VCol,{attrs:{"cols":"12"}},[_c('p',[_vm._v("Disabilitando il settaggio, le pratiche che lo contengono continueranno a visualizzarlo, ma non sarà possibile spostare un elemento sotto questo stato. Accertarsi di voler continuare.")])])],1)],1),_c(VCardActions,[_c(VBtn,{staticClass:"ma-2",attrs:{"disabled":_vm.loading},on:{"click":_vm.closeModal}},[_vm._v(" Chiudi ")]),_c(VSpacer),_c(VBtn,{staticClass:"ma-2",attrs:{"color":"red","loading":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.disableSetting.apply(null, arguments)}}},[_vm._v(" Disabilita ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }