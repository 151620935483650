import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticClass:"mt-2",attrs:{"justify":"center"}},[_c(VExpansionPanels,{attrs:{"inset":"","focusable":""}},_vm._l((_vm.companySettingsList),function(setting){return _c(VExpansionPanel,{key:setting.id},[_c(VExpansionPanelHeader,{attrs:{"icon-rotate":""}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(setting.name)+" ")]),_c(VCol,{staticClass:"text--secondary",attrs:{"cols":"8"}},[_c(VFadeTransition,{attrs:{"leave-absolute":""}},[_c(VRow,{staticStyle:{"width":"100%"},attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(setting.description)+" ")])],1)],1)],1)],1)],1),_c(VExpansionPanelContent,[_c(VList,{attrs:{"flat":"","subheader":"","three-line":""}},[_c(VListItemGroup,{attrs:{"multiple":"","active-class":""}},[_c(VListItem,[_c(VListItemContent,[_c(VRow,[_c(VCol,{staticClass:"text-right",attrs:{"cols":"12"}},[_c(VBtn,{attrs:{"color":"primary","small":""},on:{"click":function($event){_vm.setModalConfigObj(setting);_vm.is_show.addSetting=true;}}},[_vm._v(" CREA NUOVO ")])],1)],1)],1)],1),_vm._l((setting.company_setting_values),function(item,index){return _c(VListItem,{key:index},[[_c(VListItemContent,[_c(VRow,[_c(VCol,{attrs:{"cols":"1"}},[(item.status === 'enabled')?_c(VIcon,{staticClass:"mr-2",attrs:{"color":"success"}},[_vm._v(" mdi-checkbox-marked ")]):_c(VIcon,{staticClass:"mr-2",attrs:{"color":"lightgray"}},[_vm._v(" mdi-minus-box ")])],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VChip,{attrs:{"label":"","color":item.color,"text-color":item.color_text}},[_vm._v(" "+_vm._s(item.name)+" ")])],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VListItemSubtitle,[_vm._v(_vm._s(item.description))])],1),_c(VCol,{staticClass:"d-flex justify-end",attrs:{"cols":"2"}},[_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c(VList,[_c(VListItem,{on:{"click":function($event){_vm.setModalConfigItemObj(item);_vm.is_show.editSettingItem=true;}}},[_c(VListItemTitle,[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v(" mdi-pencil ")]),_vm._v(" Modifica ")],1)],1),(item.company_id > 0 && item.status !== 'disabled')?_c(VListItem,{on:{"click":function($event){_vm.setModalConfigItemObj(item);_vm.is_show.deleteSettingItem=true;}}},[_c(VListItemTitle,[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v(" mdi-delete ")]),_vm._v(" Disabilita ")],1)],1):(item.company_id > 0 && item.status === 'disabled')?_c(VListItem,{on:{"click":function($event){_vm.setModalConfigItemObj(item);_vm.is_show.reactivateSettingItem=true;}}},[_c(VListItemTitle,[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v(" mdi-autorenew ")]),_vm._v(" Riattiva ")],1)],1):_vm._e()],1)],1)],1)],1)],1)]],2)})],2)],1)],1)],1)}),1),_c('create-setting',{attrs:{"is_show":_vm.is_show.addSetting,"modalConfigObj":_vm.modalConfigObj},on:{"close":function($event){_vm.is_show.addSetting=false},"reload-data":_vm.reloadData}}),_c('edit-setting',{attrs:{"is_show":_vm.is_show.editSettingItem,"modalConfigItemObj":_vm.modalConfigItemObj},on:{"close":function($event){_vm.is_show.editSettingItem=false},"reload-data":_vm.reloadData}}),_c('delete-setting',{attrs:{"is_show":_vm.is_show.deleteSettingItem,"modalConfigItemObj":_vm.modalConfigItemObj},on:{"close":function($event){_vm.is_show.deleteSettingItem=false},"reload-data":_vm.reloadData}}),_c('reactivate-setting',{attrs:{"is_show":_vm.is_show.reactivateSettingItem,"modalConfigItemObj":_vm.modalConfigItemObj},on:{"close":function($event){_vm.is_show.reactivateSettingItem=false},"reload-data":_vm.reloadData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }