<template>
  <div>
    <v-dialog
      v-model="is_show"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Aggiungi una configurazione</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            @submit.prevent="create"
          >
            <v-row class="ma-0">
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="form.name"
                  label="Titolo"
                  outlined
                  required
                  dense
                  :rules="[formRules.required]"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="form.description"
                  label="Descrizione"
                  rows="3"
                  dense
                  outlined
                ></v-textarea>
              </v-col>

              <v-col
                cols="12"
              >
                <v-color-picker
                  v-model="testColor"
                  dot-size="23"
                  swatches-max-height="173"
                  mode="hexa"
                  hide-mode-switch
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-switch
                  v-model="form.color_text"
                  label="Testo in bianco"
                  class="ma-2"
                  true-value="white"
                  false-value="black"
                ></v-switch>
              </v-col>
              <v-col cols="12">
                <v-chip
                  :color="form.color"
                  :text-color="form.color_text"
                  label
                >
                  Prova colore
                </v-chip>
              </v-col>

              <v-col
                cols="12"
                class="text-right"
              >
                <v-btn
                  class="ma-2"
                  :disabled="loading"
                  @click="closeModal"
                >
                  Chiudi
                </v-btn>
                <v-btn
                  color="primary"
                  class="ma-2"
                  :loading="loading"
                  @click.prevent="create"
                >
                  Crea
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'SettingCreate',
  props: {
    is_show: {
      type: Boolean,
      default: false,
    },
    modalConfigObj: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      form: {},
      testColor: '#C6C6C6FF',
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['formRules']),
  },
  watch: {
    testColor: {
      handler(newValue) {
        this.form.color = newValue
      },
    },
  },
  methods: {
    async create() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          this.form.setting_type = this.modalConfigObj.setting_type
          this.form.company_setting_id = this.modalConfigObj.id

          await this.$api.createSetting(this.form)
          this.is_show = false
          await this.$store.dispatch('set_alert', { msg: 'Configurazione creata con successo', type: 'success' })
          this.$emit('reload-data')
          await this.closeModal()
        } catch (e) {
          this.loading = false
          await this.$store.dispatch('set_alert', { msg: e.response.data.message, type: 'error' })
        }
      }
    },
    async closeModal() {
      await this.$refs.form.reset()
      this.testColor = '#C6C6C6FF'
      await this.$emit('close')
      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>
