<template>
  <div>
    <v-dialog
      v-model="is_show"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Modifica una configurazione</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            @submit.prevent="edit"
          >
            <v-row class="ma-0">
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="localSettingData.name"
                  label="Titolo"
                  outlined
                  required
                  dense
                  :rules="[formRules.required]"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="localSettingData.description"
                  label="Descrizione"
                  rows="3"
                  dense
                  outlined
                ></v-textarea>
              </v-col>
              <v-col
                cols="12"
              >
                <v-color-picker
                  v-model="testColor"
                  dot-size="23"
                  swatches-max-height="173"
                  mode="hexa"
                  hide-mode-switch
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-switch
                  v-model="localSettingData.color_text"
                  label="Testo in bianco"
                  class="ma-2"
                  true-value="white"
                  false-value="black"
                ></v-switch>
              </v-col>
              <v-col cols="12">
                <v-chip
                  :color="localSettingData.color"
                  :text-color="localSettingData.color_text"
                  label
                >
                  Prova colore
                </v-chip>
              </v-col>
              <v-col
                cols="12"
                class="text-right"
              >
                <v-btn
                  class="ma-2"
                  :disabled="loading"
                  @click="closeModal"
                >
                  Chiudi
                </v-btn>
                <v-btn
                  color="primary"
                  class="ma-2"
                  :loading="loading"
                  @click.prevent="edit"
                >
                  Modifica
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'SettingEdit',
  props: {
    is_show: {
      type: Boolean,
      default: false,
    },
    modalConfigItemObj: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      initialForm: {},
      form: {},
      loading: false,
      localSettingData: {},
      colorz: '',
      testColor: '',
    }
  },
  computed: {
    ...mapGetters(['formRules']),
  },
  watch: {
    is_show(newShow) {
      if (newShow) {
        this.localSettingData = JSON.parse(JSON.stringify(this.modalConfigItemObj))
        this.testColor = this.localSettingData.color
      }
    },
    testColor: {
      handler(newValue) {
        this.localSettingData.color = newValue
      },
      deep: true,
    },
  },
  methods: {
    async edit() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true

          await this.$api.editSetting(this.localSettingData.id, this.localSettingData)
          this.is_show = false
          await this.$store.dispatch('set_alert', { msg: 'Configurazione modificata con successo', type: 'success' })
          this.$emit('reload-data')
          await this.closeModal()
        } catch (e) {
          this.loading = false
          await this.$store.dispatch('set_alert', { msg: e.response.data.message, type: 'error' })
        }
      }
    },
    async closeModal() {
      await this.$refs.form.reset()
      await this.$emit('close')
      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>
